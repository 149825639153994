<template>
  <div>
    <div class="form--group form--group-switch">
      <div class="left--col">
        <span>{{ $t('addListing.primary') }}</span>
        <span class="required">*</span>
      </div>
      <div class="right--col">
        <button type="button" class="btn px-4" :class="{ selected: isConditionPrimaryValue === 1 }">
          {{ $t('general.yes') }}
        </button>
        <button
          type="button"
          class="btn px-4"
          :disabled="true"
          :class="{ selected: isConditionPrimaryValue === 0 }"
        >
          <span style="position: relative">
            {{ $t('general.no') }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'rent-buy-switch',
  data() {
    return {
      isConditionPrimaryValue: 1,
    };
  },
  computed: {
    ...mapState({
      listingType: (state) => state.v2.listingForm.listingType,
    }),
  },
  methods: {},
};
</script>

<style scoped></style>
